define(['app'], (app) => {

  const foundationFinderMultiBrandLoadingNoCardLoading = () => {
    const component = {};

    component.config = {
      selectors: {
        loadingMessageContainer: '.foundationFinderMultiBrand_noCardLoadingScreen_loading',
        errorMessageContainer:  '.foundationFinderMultiBrand_noCardLoadingScreen_error',
        retakeButtonContainer: '.foundationFinderMultiBrand_noCardLoadingScreen_error_retakeQuiz_container',
        retakeButtonDesktop: '.foundationFinderMultiBrand_noCardLoadingScreen_error_retakeQuiz_desktop',
        reloadButton: '.foundationFinderMultiBrand_noCardLoadingScreen_error_reload',
        retakeButton: '.foundationFinderMultiBrand_noCardLoadingScreen_error_retakeQuiz'
      },
      channels: {
        matchesLoading: 'foundationFinderMultiBrandMatches/noCardLoading',
        matchesSuccess: 'foundationFinderMultiBrandMatches/noCardSuccess',
        matchesError: 'foundationFinderMultiBrandMatches/noCardError',
        retakeQuiz: 'foundationFinderMultiBrandQuiz/retakeQuiz',
        reloadNoCardRequest: 'foundationFinderMultiBrandQuiz/reloadNoCardRequest',
        results: 'foundationFinder/results'
      },
      attributes: {
        hide: 'data-hide',
      }
    };

    component.init = (element) => {
      component.element = element;
      component.loadingMessageContainer = element.querySelector(component.config.selectors.loadingMessageContainer);
      component.errorMessageContainer = element.querySelector(component.config.selectors.errorMessageContainer);
      component.retakeButtonContainer =  element.querySelector(component.config.selectors.retakeButtonContainer);
      component.retakeButton =  element.querySelector(component.config.selectors.retakeButton);
      component.retakeButtonDesktop =  element.querySelector(component.config.selectors.retakeButtonDesktop);
      component.reloadButton =  element.querySelector(component.config.selectors.reloadButton);
      component.subscribe();

      component.bind();
    };

    component.subscribe = () => {
      app.subscribe(component.config.channels.matchesLoading, component.showLoadingScreen);
      app.subscribe(component.config.channels.matchesSuccess, component.hideLoadingScreen);
      app.subscribe(component.config.channels.matchesError, component.showErrorMessage);
    };

    component.bind = () => {
      component.retakeButton.addEventListener('click', component.retakeQuiz);
      component.retakeButtonDesktop.addEventListener('click', component.retakeQuiz);
      component.reloadButton.addEventListener('click', component.reload);
    };

    component.retakeQuiz = () => {
      component.element.setAttribute(component.config.attributes.hide, 'true');
      component.hideErrorMessage();
      app.publish(component.config.channels.retakeQuiz);
    };

    component.reload = () => {
      component.element.setAttribute(component.config.attributes.hide, 'true');
      component.hideErrorMessage();
      app.publish(component.config.channels.reloadNoCardRequest);
    };

    component.hideErrorMessage = () => {
      component.loadingMessageContainer.setAttribute(component.config.attributes.hide, 'false');
      component.errorMessageContainer.setAttribute(component.config.attributes.hide, 'true');
      component.retakeButtonContainer.setAttribute(component.config.attributes.hide, 'true');
    };

    component.showLoadingScreen = () => {
      component.element.setAttribute(component.config.attributes.hide, 'false');
    };

    component.hideLoadingScreen = (response, facetFilters) => {
      component.element.setAttribute(component.config.attributes.hide, 'true');
      app.publish(component.config.channels.results, response, facetFilters, false);
    };

    component.showErrorMessage = () => {
      component.loadingMessageContainer.setAttribute(component.config.attributes.hide, 'true');
      component.errorMessageContainer.setAttribute(component.config.attributes.hide, 'false');
      component.retakeButtonContainer.setAttribute(component.config.attributes.hide, 'false');
    };

    return component;
  };

  return foundationFinderMultiBrandLoadingNoCardLoading;
});
